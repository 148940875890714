import React, { useEffect, useState } from 'react';
import DailyVotingChart from '../../../components/charts/dailyVotingBarchart';
import { baseurl } from '../../../components/lib/settings';
import { Select, Row, Col, Card, Typography, Alert } from 'antd';
import "./dailyvotings.css";

const { Option } = Select;
const { Title } = Typography;

const DailyVotingList = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(String(new Date().getMonth() + 1).padStart(2, '0'));
    const [selectedYear, setSelectedYear] = useState(String(new Date().getFullYear()));
    const [permissions, setPermissions] = useState([]);
    const [delegationList, setDelegationList] = useState([]);
    const [selectedDelegation, setSelectedDelegation] = useState(null);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const years = Array.from({ length: currentYear - 2023 }, (_, i) => (2024 + i).toString());

    const months = [
        { label: 'Janeiro', value: '01' },
        { label: 'Fevereiro', value: '02' },
        { label: 'Março', value: '03' },
        { label: 'Abril', value: '04' },
        { label: 'Maio', value: '05' },
        { label: 'Junho', value: '06' },
        { label: 'Julho', value: '07' },
        { label: 'Agosto', value: '08' },
        { label: 'Setembro', value: '09' },
        { label: 'Outubro', value: '10' },
        { label: 'Novembro', value: '11' },
        { label: 'Dezembro', value: '12' }
    ].filter(month => {
        if (selectedYear === currentYear.toString()) {
            return month.value <= String(currentMonth).padStart(2, '0');
        }
        return true;
    }).filter(month => selectedYear >= '2024' || month.value >= '06');

    useEffect(() => {
        setLoading(true);
        setError(null);

        baseurl.get('v3/get_delegation_list/')
            .then((resp) => {
                setDelegationList(resp.data);
            }).catch((error) => {
                console.error(error);
            });

        baseurl.get('v1/check_userpermissions/')
            .then((resp) => {
                setPermissions(resp.data);
            }).catch((error) => {
                console.error(error);
            });

        baseurl.post("v3/monthly-votings-by-day/", { selectedDelegation, selectedMonth, selectedYear })
            .then((response) => {
                if (response.data && response.data.daily_votes && response.data.daily_votes.length > 0) {
                    setData(response.data);
                } else {
                    setData(null);
                }
                setLoading(false);
            })
            .catch((error) => {
                setData(null);
                setError(error);
                setLoading(false);
            });
    }, [selectedDelegation, selectedMonth, selectedYear]);

    if (error) {
        return <Alert message="Erro ao carregar dados" description={error.message} type="error" showIcon />;
    }

    const isAdmin = permissions.some(
        (perm) => perm.finance_administrator || perm.system_administrator
    );

    return (
        <div style={{ padding: '20px' }}>
            <Card>
                <div className='backback'>
                    <Title
                        level={2}
                        className="sticky-header"
                        style={{
                            fontFamily: 'Montserrat, sans-serif',
                            margin: '0',
                            marginBottom: "30px"
                        }}
                    >
                        Votos Diários
                    </Title>
                </div>

                <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                    {isAdmin && (
                        <Col span={8}>
                            <Select
                                placeholder="Selecione uma delegação"
                                style={{ width: '100%' }}
                                onChange={(value) => setSelectedDelegation(value)}
                            >
                                {delegationList.map((delegation) => (
                                    <Option key={delegation.id} value={delegation.id}>
                                        {delegation.description}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    <Col span={8}>
                        <Select
                            value={selectedYear}
                            onChange={(value) => setSelectedYear(value)}
                            style={{ width: '100%' }}
                        >
                            {years.map((year) => (
                                <Option key={year} value={year}>{year}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select
                            value={selectedMonth}
                            onChange={(value) => setSelectedMonth(value)}
                            style={{ width: '100%' }}
                        >
                            {months.map((month) => (
                                <Option key={month.value} value={month.value}>{month.label}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </Card>
            <div>
                {data ? (
                    <DailyVotingChart data={data} />
                ) : (
                    <p>Não há dados para exibir.</p>
                )}
            </div>
        </div>
    );
};

export default DailyVotingList;
