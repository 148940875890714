import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { Menu, Layout, Spin } from "antd";
import { baseurl } from "../lib/settings";
import logo from "../../assets/logos/rm-logo.png"
import { LoadingOutlined } from '@ant-design/icons';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  AppstoreTwoTone,
  VideoCameraOutlined,
  AimOutlined
} from '@ant-design/icons';

const Sidebar = ({ collapsed }) => {
  const { Sider } = Layout;
  const [selectedLink, setSelectedLink] = useState("/voting/all");
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyLogo, setCompanyLogo] = useState(logo);
  const [isCompanyDetailsFetched, setIsCompanyDetailsFetched] = useState(false);

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
    display: 'flex',
    flexDirection: 'column',
  };

  const logoStyle = {
    height: 70,
    margin: '16px',
    background: '#000F15',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const location = useLocation();

  useEffect(() => {
    setSelectedLink(location.pathname);
    baseurl.get("v1/check_userpermissions/").then((resp) => {
      setPermissions(resp.data);
    }).catch((error) => {
    });
    if (!isCompanyDetailsFetched) {
      getCompanyDetails();
    }
  }, [location.pathname]);

  const getCompanyDetails = async () => {
    try {
      setLoading(true);
      const response = await baseurl.post("v2/ngoma/company_details/");
      const logoUrl = response.data.length > 0 ? response.data[0].logo : logo;
      setCompanyLogo(logoUrl);
      setLoading(false);
      setIsCompanyDetailsFetched(true);
    } catch (error) {
      setLoading(false);
    }
  };

  const items = [
    {
      key: '/dashboard',
      icon: <AppstoreOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: '/voting/all',
      icon: <VideoCameraOutlined />,
      label: <Link to="/voting/all">Votos</Link>,
    },
    {
      key: '/voting/category',
      icon: <BarChartOutlined />,
      label: <Link to="/voting/category">Filtros</Link>,
    },
    {
      key: '/voting/search/personalised',
      icon: < AimOutlined/>,
      label: <Link to="/voting/search/personalised">Relatório geral</Link>,
    },
    {
      key: '/musician/all',
      icon: <CloudOutlined />,
      label: <Link to="/musician/all">Músicos</Link>,
    },
    {
      key: 'admin',
      icon: <AppstoreOutlined />,
      label: "Administração",
      visible: permissions.length > 0 && permissions[0].voting_administrator,
      children: [
        {
          key: '/admin/musician/create',
          icon: <TeamOutlined />,
          label: <Link to="/admin/musician/create">Criar Músico</Link>,
        },
        {
          key: '/admin/musician/all',
          icon: <ShopOutlined />,
          label: <Link to="/admin/musician/all">Lista de Músicos</Link>,
        },
      ],
    },
    {
      key: 'finance',
      icon: <ShopOutlined />,
      label: "Finanças",
      visible: permissions.length > 0 && permissions[0].finance_administrator,
      children: [
        {
          key: '/admin/finance/reports',
          icon: <ShopOutlined />,
          label: <Link to="/admin/finance/reports">Relatórios</Link>,
        },
        {
          key: '/admin/finance/query/votings',
          icon: <ShopOutlined />,
          label: <Link to="/admin/finance/query/votings">Consultas</Link>,
        },
      ],
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={siderStyle}
    >
      <div style={logoStyle}>
        {loading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        ) : (
          <img src={companyLogo} alt="Logotipo" className="company-logo" />
        )}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedLink]}
        onClick={(e) => setSelectedLink(e.key)}
      >
        {items.map(item => (
          item.visible !== false && item.children ? (
            <Menu.SubMenu
              key={item.key}
              icon={item.icon}
              title={item.label}
            >
              {item.children.map(subItem => (
                <Menu.Item key={subItem.key} icon={subItem.icon} disabled={subItem.disabled}>
                  {subItem.label}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : item.visible !== false && (
            <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
              {item.label}
            </Menu.Item>
          )
        ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
