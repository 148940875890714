import axios from "axios";
export const baseurl = axios.create({
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: "http://162.0.237.160:4523/api",
  // baseURL: "https://api.voting.condominio.co.mz/api",
});


baseurl.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token != null) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
baseurl.interceptors.response.use(function (response) {
  const statusCode = response.status;

  if (statusCode === 401) {
    window.alert("A sua sessão expirou. Por favor faça um novo login.")
    window.location.replace('/')
  }

  return response;
}, function (error) {
  if (error.response) {
    const statusCode = error.response.status;
    if (statusCode === 401) {
      window.alert("A sua sessão expirou. Por favor faça um novo login.")
      window.location.replace('/')
    }
  } else if (error.request) {
    console.log('No response received:', error.request);
  } else {
    console.log('Error message:', error.message);
  }
  return Promise.reject(error);
});