import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from "../../assets/logos/rb_2148509538.png"

function NotFound404() {
  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img
          src={NotFoundImage}
          alt="Página não encontrada"
          style={styles.image}
        />
      </div>
      <h1 style={styles.heading}>A página solicitada não foi encontrada</h1>
      <p style={styles.text}>
        A página que está procurando não existe.{' '}<br /><br />
        <Link to="/" style={styles.link}>
          Voltar para o login
        </Link>
      </p>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  image: {
    width: '400px',
    height: 'auto',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '16px',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
};

export default NotFound404;
