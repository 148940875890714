import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Table, Card, Skeleton, DatePicker, Input, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { baseurl } from '../../../components/lib/settings';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'; // Para gerar tabelas automaticamente no PDF
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

const PersonalizedSearch = () => {
    const [votingList, setVotingList] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [dateInfo, setDateInfo] = useState({ from_date: '', due_date: '', delegation_name: '' });

    useEffect(() => {
        setLoadingTable(true);
        baseurl
            .get("v1/web/all_votings_all_and_filter/")
            .then((resp) => {
                setVotingList(resp.data.filter_info || []);

                setLoadingTable(false);
            })
            .catch(() => {
                setLoadingTable(false);
            });
    }, []);

    const handleDateRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const [start, end] = dates;
            setDateRange([start, end]);

            setLoadingTable(true);
            baseurl.post('v3/get_personalized_report/report/', { startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD'), }).then((resp) => {
                const data = resp.data.weeks_information.map((voting, index) => ({
                    order_number: index + 1,
                    key: index.toString(),
                    musician_name: voting.total_votes.musician.author,
                    music_title: voting.total_votes.musician.title,
                    voting_code: voting.total_votes.musician.voting_code || 'N/A',
                    voting_amount: parseFloat(voting.total_amount).toFixed(2),
                    total_votes: voting.total_votes.total_number_votes || 0,
                }));
                setFilteredData(data);
                console.log('DETALHES: ', resp.data)
                setDateInfo({
                    from_date: resp.data.from_date || '',
                    due_date: resp.data.due_date || '',
                    delegation_name: resp.data.delegation_name || '',
                });
                setLoadingTable(false);
            })
                .catch(() => {
                    setLoadingTable(false);
                });
        }
    };

    const handleSearch = (value) => {
        setSearchText(value.toLowerCase());
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'order_number',
            key: 'order_number',
        },
        {
            title: 'Nome do Músico',
            dataIndex: 'musician_name',
            key: 'musician_name',
        },
        {
            title: 'Título da Música',
            dataIndex: 'music_title',
            key: 'music_title',
        },
        {
            title: 'Código de Votação',
            dataIndex: 'voting_code',
            key: 'voting_code',
            align: 'center',
        },
        {
            title: 'Valor Total de Votos por Músico',
            dataIndex: 'voting_amount',
            key: 'voting_amount',
            align: 'center',
        },
        {
            title: 'Número Total de Votos',
            dataIndex: 'total_votes',
            key: 'total_votes',
            align: 'center',
        },
    ];

    const filteredResults = filteredData.filter((item) =>
        item.musician_name.toLowerCase().includes(searchText) ||
        item.music_title.toLowerCase().includes(searchText) ||
        (item.voting_code && item.voting_code.toLowerCase().includes(searchText))
    );

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const exportPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(18);
        doc.text(`Relatório Geral - ${dateInfo.delegation_name}`, 14, 20);

        doc.setFontSize(12);
        doc.text(`Período: ${dateInfo.from_date} a ${dateInfo.due_date}`, 14, 30);

        const tableData = filteredResults.map(item => [
            item.order_number,
            item.musician_name,
            item.music_title,
            item.voting_code,
            item.voting_amount,
            item.total_votes,
        ]);

        doc.autoTable({
            head: [
                ['#', 'Músico', 'Canção', 'Código', 'Valor Total', 'Total de Votos']
            ],
            body: tableData,
            startY: 40,
            headStyles: {
                fillColor: "#FFB668",
                textColor: [255, 255, 255],
                fontSize: 12,
                fontStyle: 'bold'
            },
        });

        const currentDate = new Date().toLocaleDateString();
        doc.setFontSize(10);
        doc.text(`Processado por computador aos: ${currentDate}`, 14, doc.internal.pageSize.height - 10);

        const pageWidth = doc.internal.pageSize.width;
        const textWidth = doc.getTextWidth('Speranza Lda');
        const xPosition = pageWidth - textWidth - 14;

        doc.setFontSize(10);
        doc.text('Speranza Lda.', xPosition, doc.internal.pageSize.height - 10);

        doc.save('relatorio_geral.pdf');
    }


    return (
        <Row justify="center">
            <Card
                className="divcard"
                span={24}
                style={{
                    width: "100%",
                }}
            >
                <Col span={24}>
                    <Title
                        level={2}
                        className="sticky-header"
                        style={{
                            fontFamily: 'Montserrat, sans-serif',
                            margin: '0',
                            marginBottom: "30px",
                        }}
                    >
                        Relatório Geral
                    </Title>
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%', marginBottom: '10px' }}>
                            <span>Escolha o intervalo de datas&nbsp;&nbsp;</span>
                            <RangePicker
                                format="DD/MM/YYYY"
                                onChange={handleDateRangeChange}
                                style={{ flex: '1 1 auto' }}
                            />
                        </div>
                        <span>Período: {dateInfo.from_date} a {dateInfo.due_date}</span>
                    </div>
                    <Search
                        placeholder="Pesquisar músico ou título da música"
                        onChange={(e) => handleSearch(e.target.value)}
                        style={{ marginBottom: '10px', width: '100%' }}
                    />
                    <Button
                        type="primary"
                        style={{ marginBottom: '20px' }}
                        onClick={exportPDF}
                    >
                        Exportar PDF
                    </Button>
                    {loadingTable ? (
                        <Skeleton active style={{ width: '100%', textAlign: 'center' }} />
                    ) : (
                        <div style={{ overflowX: 'auto' }}>
                            <Card>
                                <Table
                                    dataSource={filteredResults}
                                    columns={columns}
                                    pagination={{ pageSize: 10 }}
                                />
                            </Card>
                        </div>
                    )}
                </Col>
            </Card>
        </Row>
    );
};

export default PersonalizedSearch;
