import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Divider } from 'antd';

const DailyVotingChart = ({ data }) => {
    const createDaysArray = (numDays) => Array.from({ length: numDays }, (_, i) => i + 1);

    const daysWithData = data.daily_votes.map(item => new Date(item.day).getDate());
    const votesWithData = data.daily_votes.map(item => item.total_votes);

    const numDaysInMonth = new Date(data.year, data.month, 0).getDate();
    const allDays = createDaysArray(numDaysInMonth);

    const votesMap = data.daily_votes.reduce((map, item) => {
        const day = new Date(item.day).getDate();
        map[day] = item.total_votes;
        return map;
    }, {});

    const votes = allDays.map(day => votesMap[day] || 0);

    const options = {
        title: {
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Votos'],
        },
        xAxis: {
            type: 'category',
            data: allDays,
            axisLabel: {
                interval: 0
            }
        },
        yAxis: {
            type: 'value',
            name: 'Votos'
        },
        series: [
            {
                name: 'Votos',
                type: 'bar',
                data: votes,
                itemStyle: {
                    color: '#3398DB'
                }
            }
        ]
    };

    return (
        <div style={{ width: '100%', }}>
            <ReactECharts option={options} />
            <Divider orientation='left'>
                <span>* Número total de votos por cada dia do mês.</span>
            </Divider>
        </div>
    );
};

export default DailyVotingChart;
