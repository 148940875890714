import React, { useState, useEffect } from "react";
import { Button, Drawer, Menu, Modal, Spin } from "antd";
import { MenuOutlined, AppstoreOutlined, VideoCameraOutlined, BarChartOutlined, CloudOutlined, TeamOutlined, ShopOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import logo from "../../assets/logos/rm-logo.png";
import "./MobileDashboardLayout.css";
import { baseurl } from "../../components/lib/settings";

const MobileDashboardLayout = ({ children }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [companyLogo, setCompanyLogo] = useState(logo);
    const [isCompanyDetailsFetched, setIsCompanyDetailsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [userName, setUserName] = useState("");
    const [userNameLoading, setUserNameLoading] = useState(true);

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const fetchName = () => {
        setUserNameLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            console.log("Fetched user from localStorage:", user);
            if (user && user.user_name) {
                setUserName(user.user_name);
            } else {
                console.log("No user name found in localStorage");
                setUserName("Nome não disponível");
            }
        } catch (error) {
            console.error("Failed to fetch username:", error);
            setUserName("Erro ao carregar nome");
        } finally {
            setUserNameLoading(false);
        }
    };

    useEffect(() => {
        fetchName();

        baseurl.get("v1/check_userpermissions/")
            .then((resp) => {
                setPermissions(resp.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });

        if (!isCompanyDetailsFetched) {
            getCompanyDetails();
        }

        const handleStorageChange = (event) => {
            if (event.key === 'user') {
                fetchName();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

    }, []);



    const getCompanyDetails = async () => {
        try {
            setLoading(true);
            const response = await baseurl.post("v2/ngoma/company_details/");
            const logoUrl = response.data.length > 0 ? response.data[0].logo : logo;
            setCompanyLogo(logoUrl);
            setLoading(false);
            setIsCompanyDetailsFetched(true);
        } catch (error) {
            setLoading(false);
        }
    };

    const doLogout = async () => {
        setModalVisible(true);
        setIsLoading(true);
        try {
            await baseurl.post("auth/logout-session/");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.replace("/");
        } catch (error) {
            console.error("Logout failed:", error);
        } finally {
            setIsLoading(false);
            setModalVisible(false);
        }
    };

    const menuItems = [
        {
            key: '/dashboard',
            icon: <AppstoreOutlined />,
            label: <Link to="/dashboard" onClick={() => setDrawerVisible(false)}>Dashboard</Link>,
        },
        {
            key: '/voting/all',
            icon: <VideoCameraOutlined />,
            label: <Link to="/voting/all" onClick={() => setDrawerVisible(false)}>Votos</Link>,
        },
        {
            key: '/voting/category',
            icon: <BarChartOutlined />,
            label: <Link to="/voting/category" onClick={() => setDrawerVisible(false)}>Filtros</Link>,
        },
        {
            key: '/musician/all',
            icon: <CloudOutlined />,
            label: <Link to="/musician/all" onClick={() => setDrawerVisible(false)}>Músicos</Link>,
        },

        {
            key: 'admin',
            icon: <AppstoreOutlined />,
            label: "Administração",
            visible: permissions.length > 0 && permissions[0].voting_administrator,
            children: [
                {
                    key: '/admin/musician/create',
                    icon: <TeamOutlined />,
                    label: <Link to="/admin/musician/create" onClick={() => setDrawerVisible(false)}>Criar Músico</Link>,
                },
                {
                    key: '/admin/musician/all',
                    icon: <ShopOutlined />,
                    label: <Link to="/admin/musician/all" onClick={() => setDrawerVisible(false)}>Lista de Músicos</Link>,
                },
            ],
        },
        {
            key: 'finance',
            icon: <ShopOutlined />,
            label: "Finanças",
            visible: permissions.length > 0 && permissions[0].finance_administrator,
            children: [
                {
                    key: '/admin/finance/reports',
                    icon: <ShopOutlined />,
                    label: <Link to="/admin/finance/reports" onClick={() => setDrawerVisible(false)}>Relatórios</Link>,
                },
                {
                    key: '/admin/finance/query/votings',
                    icon: <ShopOutlined />,
                    label: <Link to="/admin/finance/query/votings" onClick={() => setDrawerVisible(false)}>Consultas</Link>,
                },
            ],
        },
    ];

    return (
        <>
            <div className="mobile-dashboard">
                <div className="navbar">
                    <div className="navbar-logo">
                        <img src={companyLogo} alt="Logotipo" style={{ height: "50px" }} />
                    </div>
                    <MenuOutlined className="menu-icon" onClick={toggleDrawer} />
                </div>

                <Drawer
                    title="Menu"
                    placement="right"
                    onClose={toggleDrawer}
                    visible={drawerVisible}
                    width={250}
                >
                    <span style={{ marginRight: 5, fontSize: '16px' }}>
                        <b><u>{userName}</u></b>
                    </span>
                    {loading ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    ) : (
                        <Menu mode="vertical">
                            {menuItems.map(item => (
                                item.visible !== false && item.children ? (
                                    <Menu.SubMenu
                                        key={item.key}
                                        icon={item.icon}
                                        title={item.label}
                                    >
                                        {item.children.map(subItem => (
                                            <Menu.Item key={subItem.key} icon={subItem.icon}>
                                                {subItem.label}
                                            </Menu.Item>
                                        ))}
                                    </Menu.SubMenu>
                                ) : item.visible !== false && (
                                    <Menu.Item key={item.key} icon={item.icon}>
                                        {item.label}
                                    </Menu.Item>
                                )
                            ))}
                        </Menu>
                    )}
                    <div className="logout-container" style={{ marginTop: '20px' }}>
                        <Button type="primary" danger onClick={doLogout} style={{ width: '100%' }}>
                            Sair
                        </Button>
                    </div>
                </Drawer>

                <div className="content">
                    {children}
                </div>
            </div>
            <Modal
                title="Terminando sessão"
                visible={modalVisible}
                footer={null}
                closable={false}
                maskClosable={false}
                bodyStyle={{ textAlign: 'center' }}
            >
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 48,
                            }}
                            spin
                        />
                    }
                />
                <br /><br />
                Por favor aguarde...
            </Modal>
        </>
    );
};

export default MobileDashboardLayout;
