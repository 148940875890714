import React, { useEffect, useState } from 'react';
import { Table, Typography, Spin, Alert, Card, Select } from 'antd';
import { baseurl } from '../../components/lib/settings';
import './VotingQuery.css';
import { format } from 'date-fns';
import { LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

function VotingQuery() {
    const [error, setError] = useState("");
    const [votingQueryData, setVotingQueryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        fetchVotingData(selectedYear);
    }, [JSON.stringify(tableParams), selectedYear]);

    const fetchVotingData = async (year) => {
        setLoading(true);
        try {
            const response = await baseurl.post("v3/ngoma/administrator/musician/query_votings/amount/", { year });
            const data = response.data;

            const filteredData = data.filter(item => new Date(item.created_at).getFullYear() === year);

            const total = filteredData.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);

            setVotingQueryData(filteredData);
            setTotalAmount(total);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const formatEndDate = (formatDate) => {
        return format(new Date(formatDate), "dd/MM/yyyy");
    };

    const columns = [
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Contacto',
            dataIndex: 'msisdn',
            key: 'msisdn',
        },
        {
            title: 'Montante (MZN)',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'MZN' })
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => formatEndDate(created_at),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= 2023; i--) {
            years.push(<Option key={i} value={i}>{i}</Option>);
        }
        return years;
    };

    return (
        <div className="voting-query-container" style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <Title
                level={2}
                style={{
                    fontFamily: 'Montserrat, sans-serif',
                    margin: '0',
                }}
            >
                Relatório de consultas
            </Title>
            {error && <Alert message="Erro" description={error} type="error" showIcon />}
            Escolha o ano: &nbsp; <Select
                defaultValue={selectedYear}
                style={{ width: 120, marginBottom: '20px' }}
                onChange={handleYearChange}
            >
                {generateYearOptions()}
            </Select>
            {loading ? (
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 25,
                            }}
                            spin
                        />
                    }
                    tip="Carregando dados..." size="large" />
            ) : (
                <Card style={{ backgroundColor: "#eeeeee" }}>
                    <div className="total-amount">
                        <h2>Total: {totalAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'MZN' })} </h2>
                    </div>
                    <div style={{ overflowX: 'auto' }}>

                        <Table
                            columns={columns}
                            dataSource={votingQueryData}
                            rowKey="id"
                            loading={loading}
                            onChange={handleTableChange}
                            pagination={{ pageSize: 100 }}
                            style={{ minWidth: '600px' }}
                        />
                    </div>
                </Card>
            )}
        </div>
    );
}
export default VotingQuery;